const host = window.location.hostname

const environment: Record<string, string> = {
  'localhost': 'local',
  'dev.soundstage.studio': 'development',
  'stage.soundstage.studio': 'staging',
  'app.soundstage.studio': 'production'
}

const env = environment[host] ?? 'local'

export default env
