/**
 * Upsert the FCM token to the backend.
 *
 * @param userId The user ID to associate with the token.
 * @param token The FCM token to save.
 */
const upsertFcmToken = async ( userId: string, token: string ) => {
  const authStore = useAuthStore()
  if ( userId ) {
    const { data, error } = await authStore.supabase
      .from( 'fcm_tokens' )
      .upsert( {
        user_id: userId,
        fcm_token: token,
      } )

    if ( error ) {
      console.error( 'Error saving token:', error.message )
    } else {
      console.log( 'Token saved successfully:', data )
    }
  } else {
    console.warn( 'No authenticated user found. Token not saved.' )
  }
}

export default upsertFcmToken