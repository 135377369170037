import BaseToast from '@/components/BaseToast.vue'

const globalToastIsOpen = ref( false )
const setGlobalToastOpen = ( state: boolean ) => globalToastIsOpen.value = state
const globalToastProps = ref( {
  message: '',
  duration: 1500,
  color: 'primary',
  isOpen: false,
} )
const globalToastButtons = ref( {
  dismiss: {
    text: 'Dismiss',
    action: () => globalToastIsOpen.value = false
  }
} )
const showGlobalToast = ( message: string, color = 'primary', duration = 1500 ) => {
  globalToastIsOpen.value = true
  globalToastProps.value.message = message
  globalToastProps.value.duration = duration
  globalToastProps.value.color = color
  globalToastProps.value.isOpen = true
}

const useToast = () => {

  /**
   * Ref to store the toast state.
   */
  const toastIsOpen = ref( false )

  /**
   * Set the toast state.
   *
   * @param state The state to set the toast to.
   */
  const setToastOpen = ( state: boolean ) => toastIsOpen.value = state

  /**
   * Ref to store the toast properties.
   *
   * @link https://ionicframework.com/docs/api/toast#properties
   */
  const toastProps = ref( {
    message: '',
    duration: 1500,
    color: 'primary',
    isOpen: false,
  } )

  const toastButtons = ref( {
    dismiss: {
      text: 'Dismiss',
      action: () => setToastOpen( false )
    }
  } )

  const showToast = ( message: string, color = 'primary', duration = 1500 ) => {
    toastProps.value.message = message
    toastProps.value.duration = duration
    toastProps.value.color = color
    toastProps.value.isOpen = true
  }

  watch( toastProps.value, () => toastIsOpen.value = true )

  return {
    BaseToast,
    setToastOpen,
    showToast,
    toastIsOpen,
    toastButtons,
    toastProps,
    setGlobalToastOpen,
    showGlobalToast,
    globalToastIsOpen,
    globalToastProps,
    globalToastButtons,
  }
}

export default useToast
