import { PushNotifications } from '@capacitor/push-notifications'

const initPushNotifications = async ( userId: string ) => {

  const result = await PushNotifications.checkPermissions()

  if ( result.receive === 'granted' ) {
    console.log( 'Push notifications are already granted.' )
    registerForPushNotifications( userId )
  } else {
    console.log( 'Requesting push notification permissions...' )
    const permissionResult = await PushNotifications.requestPermissions()

    if ( permissionResult.receive === 'granted' ) {
      console.log( 'Push notifications granted. Registering...' )
      registerForPushNotifications( userId )
    } else {
      console.log( 'Push notifications permission was denied.' )
    }
  }
}

export default initPushNotifications