import {
  Session,
  createClient
} from '@supabase/supabase-js'

interface AuthStore {
  userSession: Session | null;
  sessionExpiry: number | undefined;
}

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY
const supabase = createClient( supabaseUrl, supabaseAnonKey )

const useAuthStore = defineStore( 'auth', () => {

  /**
   * State.
   */
  const state = useStorage<AuthStore>( 'auth', {
    userSession: null,
    sessionExpiry: undefined
  } )

  /**
   * Methods.
   */
  const initializeSession = async () => {
    if ( ! state.value.userSession || isSessionExpired() ) {
      const { data: { session }, error } = await supabase.auth.getSession()
      if ( session ) {
        state.value.userSession = session
        state.value.sessionExpiry = session.expires_at
        console.log( 'Session initialized:', session )
      } else if ( error ) {
        console.error( 'Session fetch error:', error )
      }
    }
  }

  const isSessionExpired = () => {
    return state.value.sessionExpiry && state.value.sessionExpiry < Date.now() / 1000
  }

  return {
    state,
    initializeSession,
    isSessionExpired,
    supabase
  }
} )

export default useAuthStore