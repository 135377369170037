<template>
  <ion-toast
    :is-open="isOpen"
    :message="message"
    :duration="duration"
    :buttons="buttons"
    :color="autoColor"
    @didDismiss="emit( 'didDismiss' )"
  />
</template>

<script setup lang="ts">
import {
  IonToast,
  ToastButton
} from '@ionic/vue'

const colorMode = useColorMode()
const autoColor = computed( () => {
  if ( color ) {
    return color
  } else {
    return colorMode.value === 'dark' ? 'light' : 'dark'
  }
} )
const emit = defineEmits( [
  'didDismiss'
] )

const {
  isOpen = false,
  duration = 1500,
  message = '',
  color = ''
} = defineProps<{
  buttons?: ToastButton[];
  isOpen: boolean;
  message: string;
  duration?: number;
  color?: string;
}>()
</script>
