import { PushNotifications } from '@capacitor/push-notifications'

const registerForPushNotifications = ( userId: string ) => {
  PushNotifications.register()

  // Listener for successful registration.
  PushNotifications.addListener( 'registration', ( token ) => {
    console.log( 'Push registration success, token: ', token.value )
    upsertFcmToken( userId, token.value )
  } )

  // Listener for registration error.
  PushNotifications.addListener( 'registrationError', ( error ) => {
    console.error( 'Push registration error: ', error )
  } )
}

export default registerForPushNotifications