<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import {
  analytics,
  chevronBack,
  home,
  library,
  reader
} from 'ionicons/icons'

const router = useRouter()
const { BaseToast, globalToastProps, globalToastIsOpen, globalToastButtons, setGlobalToastOpen, showGlobalToast } = useToast()

onMounted( async () => {
  const authStore = useAuthStore()
  await authStore.initializeSession()
  if ( authStore.state.userSession ) {
    const platform = Capacitor.getPlatform()
    if ( platform === 'web' ) {
      // TODO: Handle notifications with the Notification API.
      console.log( 'Running on the web, handle notifications with the Notification API.' )
    } else {
      initPushNotifications( authStore.state.userSession.user.id )
    }
  }
  authStore.supabase?.auth.onAuthStateChange( ( _, _session ) => {
    authStore.state.userSession = _session
  } )
} )
</script>

<template>
  <ion-app>
    <ion-split-pane content-id="main">
      <ion-menu
        v-if="router.currentRoute.value.path !== '/login' && router.currentRoute.value.path !== '/auth/confirm'"
        content-id="main-content"
        menu-id="main-menu"
      >
        <ion-header>
          <ion-toolbar>
            <ion-title>
              Soundstage
            </ion-title>
            <ion-buttons slot="end">
              <ion-menu-toggle>
                <ion-button>
                  <ion-icon :icon="chevronBack" />
                </ion-button>
              </ion-menu-toggle>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <div class="flex flex-col h-full">
            <div class="upper-links flex-grow">
              <ion-list class="text-sm bg-transparent">
                <ion-item
                  class="text-sm"
                  button
                  lines="none"
                  router-link="/home"
                >
                  <ion-icon
                    slot="start"
                    class="text-base"
                    aria-hidden="true"
                    :icon="home"
                  />
                  <ion-label>
                    Home
                  </ion-label>
                </ion-item>
                <ion-item
                  class="text-sm"
                  button
                  lines="none"
                  router-link="/projects"
                >
                  <ion-icon
                    slot="start"
                    class="text-base"
                    aria-hidden="true"
                    :icon="library"
                  />
                  <ion-label>
                    Projects
                  </ion-label>
                </ion-item>
                <ion-item
                  class="text-sm"
                  button
                  lines="none"
                  router-link="/activity"
                >
                  <ion-icon
                    slot="start"
                    class="text-base"
                    aria-hidden="true"
                    :icon="analytics"
                  />
                  <ion-label>
                    Activity
                  </ion-label>
                </ion-item>
                <ion-item
                  class="text-sm"
                  button
                  lines="none"
                  router-link="/sketchbook"
                >
                  <ion-icon
                    slot="start"
                    class="text-base"
                    aria-hidden="true"
                    :icon="reader"
                  />
                  <ion-label>
                    Sketchbook
                  </ion-label>
                </ion-item>
              </ion-list>
            </div>
            <p>
              Account
            </p>
          </div>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main" />
      <BaseToast
        :is-open="globalToastIsOpen"
        :message="globalToastProps.message"
        :duration="globalToastProps.duration"
        :buttons="[ globalToastButtons.dismiss ]"
        @didDismiss="setGlobalToastOpen( false )"
      />
    </ion-split-pane>
  </ion-app>
</template>
<style scoped>
.toolbar-overlay ion-toolbar {
  --background: transparent !important;
}

.toolbar-overlay .header-background {
  display: none;
}

ion-toolbar {
  --background: #000;
}
ion-menu {
  border-right: 1px solid rgba(200,200,200,0.1);
  /* width: 150px; */
  /* max-width: 150px; */
}
ion-item {
  --background: transparent;
  --padding-start: 0.25rem;
  --min-height: 1.75rem;
  --border-radius: 0.5rem;
  --background-hover-opacity: 0.025;
}
ion-label {
  margin: 0.25rem 0;
}
ion-item ion-icon {
  margin: 0.25rem 0.5rem 0.25rem 0;
}

ion-item:hover ion-icon {
  margin: 0.25rem 0.5rem 0.25rem 0;
  color: white;
}
ion-split-pane {
  --side-max-width: 150px;
}
</style>
